import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { createStructuredSelector } from "reselect";
import { Icon } from '@iconify/react';
import logoutCircleRLine from '@iconify/icons-ri/logout-circle-r-line';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import caretDownFill from "@iconify/icons-bi/caret-down-fill";
import notificationFill from "@iconify/icons-ri/notification-fill";

import { logoutAsync } from "../store/reducers/user/user.actions";
import { selectNumber } from "../store/reducers/user/user.selectors";

import routes from "../config/routes";

import LogoBrand from "../components/UIs/LogoBrand/LogoBrand.ui";

// import { selectAuthUser } from "../../../store/reducers/auth/auth.selectors";

// import { signoutRedirect } from "../../../utils/userManager";

import "./NavMenu.styles.scss";

const NavMenu = ({ user, match, logoutAsync, number }) => {
  const history = useHistory();
  const params = useParams();
  const logout = (e) => {
    e.preventDefault();
    // signoutRedirect();
  };

  return (
    <div className="l-header">
      <nav role="navigation" className="l-navbar navbar navbar-expand-sm">
        <div className="container-fluid">
          <Link className="navbar-brand" to={routes.root}>
            <LogoBrand brandName="" />
          </Link>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button> */}
          <div className="" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item" onClick={() => logoutAsync(number)
                                                      .then(() => {
                                                        history.push('/auth')
                                                      }).catch(() => {
                                                        history.push('/auth')
                                                      })
                                                    }
                                                      >
                {history.location.pathname != '/auth' && (
                  <Icon icon={logoutCircleRLine} color="white" width="25"/>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  number: selectNumber
});

const mapDispatchToProps = (dispatch) => ({
  logoutAsync: (msisdn) => dispatch(logoutAsync(msisdn)),
})
export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
// export default NavMenu;
