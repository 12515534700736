const userActionTypes = {
    REQUEST_AUTHENTIFICATION_LOGOUT: "REQUEST_AUTHENTIFICATION_LOGOUT",
    REQUEST_AUTHENTICATION_START: "REQUEST_AUTHENTICATION_START",
    REQUEST_AUTHENTICATION_SUCCESS: "REQUEST_AUTHENTICATION_SUCCESS",
    REQUEST_AUTHENTICATION_FAILURE: "REQUEST_AUTHENTICATION_FAILURE",
    SET_LANG: "SET_LANG",
    SET_TOKEN: "SET_TOKEN",
    SET_NUMBER: "SET_NUMBER"
};

export default userActionTypes;