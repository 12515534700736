import identificationActionTypes from "./identification.types"

const initialState = {
    infosIdentification: {
        infosTelco: null,
        infosOm: null,
        infosStatus: null
    },
    infosTelco: null,
    infosOm: null,
    infosStatus: null
};

const identificationReducer = (state = initialState, action) => {
    switch(action.type) {
        case identificationActionTypes.GET_INFOS_CLIENT:
            return {
                ...state,
                infosIdentification: {
                    ...state.infosIdentification,
                    infosTelco: action.payload.infosTelco,
                    infosOm: action.payload.infosOm,
                    infosStatus: action.payload.infosStatus
                }
            };

        case identificationActionTypes.GET_INFOS_TELCO:
            return {
                ...state,
                infosTelco: action.payload
            };
        
        case identificationActionTypes.GET_INFOS_OM:
            return {
                ...state,
                infosOm: action.payload
            };
        
        case identificationActionTypes.GET_STATUS:
            return {
                ...state,
                infosStatus: action.payload
            };

        default:
            return state;
    }
}

export default identificationReducer;