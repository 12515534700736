import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import moment from "moment";

import { Icon } from '@iconify/react';
import idCard from '@iconify/icons-bx/id-card';
import plusIcon from "@iconify/icons-akar-icons/plus";

import Badge from "../../components/UIs/Badge/Badge.ui";
import ButtonOrange from "../../components/UIs/ButtonOrange/ButtonOrange.ui";
import Container from "../../components/Layouts/Container/Container.layout";
import ContentTextLoader from "../../components/Loaders/content-text-loader/content-text-loader.component";
import AlertMessage from '../../components/UIs/AlertMessage/AlertMessage.ui';
import ErrorLoading from '../../components/UIs/ErrorLoading/ErrorLoading.ui';
import InfosIdentification from '../../components/UIs/InfosIdentification/InfosIdentification.ui';
import './home.styles.scss';

import { getIdentificationsAsync, getInfosOMAsync, getInfosTelcoAsync, getStatusAsync } from '../../store/reducers/identification/identification.action';
import { selectInfosIdentification, selectInfosOm, selectInfosTelco, selectInfosStatus } from '../../store/reducers/identification/identification.selectors';
import { selectNumber } from '../../store/reducers/user/user.selectors';
import isEmpty from '../../utils/isEmpty';
import convertStatusToColor from "../../utils/convertStatusToColor";


const Home = ({
    infosIdentification,
    getIdentificationsAsync,
    number,
    infosOm,
    infosTelco,
    infosStatus,
    getInfosOMAsync, 
    getInfosTelcoAsync, 
    getStatusAsync
}) => {

    const [loadingPage, setLoadingPage] = useState(false);
    const [loadingInfosTelco, setLoadingInfosTelco] = useState(false);
    const [loadingInfosOm, setLoadingInfosOm] = useState(false);
    const [laodingInfosStatus, setLoadingInfosStatus] = useState(false);
    const [isInfosTelcoOk, setIsInfosTelcoOk] = useState(true);
    const [isInfosOmOk, setIsInfosOmOk] = useState(true);
    const [isInfosStatusOk, setIsInfosStatusOk] = useState(true);
    const [isReload, setIsReload] = useState(false);
    const history = useHistory();
    const [conform, setConform] = useState({
        name: true,
        idCard: true,
        birthDate: true,
        nbrPhoneLine: true,
        expiredDateIdCard: true,

    });


    useEffect(() => {
        
        var isNameOk = true;
        var isIdCardOk = true;
        var isIdCardExpired = true;
        var isNbrPhoneLineOk = true;
        var isBirthDateOk = true;
        
        if((infosStatus?.nomPrenomTelco?.replace(/ /g,'').toLowerCase() != infosStatus?.nomPrenomOm?.replace(/ /g,'').toLowerCase()
        && !isEmpty(infosStatus?.nomPrenomOm?.replace(/ /g,'')) && !isEmpty(infosStatus?.nomPrenomTelco?.replace(/ /g,'')))){
            isNameOk = false;
        }

        if(!infosStatus?.numeroPieceOm?.trim().includes(infosStatus?.numeroPieceTelco?.trim())
        && !isEmpty(infosStatus?.numeroPieceOm?.trim()) && !isEmpty(infosStatus?.numeroPieceTelco?.trim())){
            isIdCardOk = false;
        }

        if(!moment(infosStatus?.dateExpirationTelco).isAfter(new Date())){
            isIdCardExpired = false;
        }

        if(!isEmpty(infosStatus?.dateNaissanceOm?.trim()) && !isEmpty(infosStatus?.dateNaissanceTelco?.trim()) 
            && infosStatus?.dateNaissanceOm?.split("T")[0] != infosStatus?.dateNaissanceTelco?.split("T")[0]){
            isBirthDateOk = false;
        }

        if(infosStatus?.nbrMsisdnTelco > 3){
            isNbrPhoneLineOk = false
        }

        setConform({
            ...conform,
            name: isNameOk,
            idCard: isIdCardOk,
            birthDate: isBirthDateOk,
            nbrPhoneLine: isNbrPhoneLineOk,
            expiredDateIdCard: true,
        })
    }, [infosStatus])

    
    useEffect(() => {
        setLoadingInfosOm(true);
        setLoadingInfosTelco(true);
        setLoadingInfosStatus(true);

        setIsInfosTelcoOk(true);
        setIsInfosOmOk(true);
        setIsInfosStatusOk(true);

        setTimeout(() => {
            
            getStatusAsync(number)
            .then((res) => {
                setLoadingInfosStatus(false);
            }).catch((err) => {
                console.error(err);
                console.log("error ", err.response);
                if(err.response.status == 401 || err.response.status == 400){
                    history.push('/auth');
                }
                setIsInfosStatusOk(false);
                setLoadingInfosStatus(false);
            });
        }, 500);
        
    }, [number, isReload]);

    return (
        <div className="info-page">
        
            <div className='info-header'>
                <FormattedMessage id="app.title" values={{number: number}}/>
            </div><br/>
            {
                isInfosStatusOk ?
                <>
                    <div className='info-badge'>
                        <>
                            {(!isEmpty(infosStatus?.numeroPieceTelco) || !isEmpty(infosStatus?.numeroPieceOm))&&
                                <div style={{marginRight: '10px'}}>
                                Statut:
                            </div>
                            }
                            {(!isEmpty(infosStatus?.numeroPieceTelco) || !isEmpty(infosStatus?.numeroPieceOm))&&
                                laodingInfosStatus ?
                                <>Loading...</>
                                :
                                    (
                                        infosStatus?.statut ?
                                        <>
                                            {(!isEmpty(infosStatus?.numeroPieceTelco) || !isEmpty(infosStatus?.numeroPieceOm))&&
                                                <Badge bgColor={convertStatusToColor("1")} style={{display: 'inline'}}>
                                                    <FormattedMessage id="app.status.ok" />
                                                </Badge>
                                            }
                                        </>
                                        :
                                        <>
                                            {(!isEmpty(infosStatus?.numeroPieceTelco) || !isEmpty(infosStatus?.numeroPieceOm))&&
                                            <Badge bgColor={convertStatusToColor("2")} style={{display: 'inline'}}>
                                                <FormattedMessage id="app.status.notOk" />
                                            </Badge>
                                            }
                                        </>
                                        
                                    )
                                
                            }
                        </>
                        
                    </div><br/>
                    <div className='row'>
                        <>
                            <div className='col-sm-6'>
                                <InfosIdentification 
                                    typeInfos="TELCO"
                                    name={infosStatus?.nomPrenomTelco} 
                                    idCNI={infosStatus?.numeroPieceTelco} 
                                    dateNaissance={moment(new Date(infosStatus?.dateNaissanceTelco)).format("DD/MM/YYYY")} 
                                    dateExpirationCNI={
                                        isEmpty(infosStatus?.dateExpirationTelco)? "":
                                        moment(new Date(infosStatus?.dateExpirationTelco)).format("DD/MM/YYYY")}
                                    numberLines={infosStatus?.nbrMsisdnTelco}
                                    loading={laodingInfosStatus}
                                    isError={isInfosStatusOk}
                                    conform={conform}
                                />
                            </div>

                            <div className='col-sm-6 info-om'>
                                <InfosIdentification 
                                    typeInfos="OM"
                                    name={infosStatus?.nomPrenomOm} 
                                    idCNI={infosStatus?.numeroPieceOm} 
                                    dateNaissance={moment(new Date(infosStatus?.dateNaissanceOm)).format("DD/MM/YYYY")} 
                                    dateExpirationCNI={
                                        isEmpty(infosStatus?.dateExpirationTelco)? "":
                                        moment(new Date(infosStatus?.dateExpirationTelco)).format("DD/MM/YYYY")}
                                    numberLines={infosStatus?.nbrMsisdnOm}
                                    loading={laodingInfosStatus}
                                    isError={isInfosStatusOk}
                                    conform={conform}
                                />                                    
                            </div>
                        </>
                    </div><br/>

                    <div className='info-footer'>

                    {!isEmpty(infosStatus?.numeroPieceTelco)&&
                        <>
                        {
                            laodingInfosStatus ?
                            <></>
                            :
                                (!moment(infosStatus?.dateExpirationTelco).isAfter(new Date()) &&
                                    <div className='badge-cni'>
                                        <Badge bgColor={convertStatusToColor("2")} style={{display: 'inline', width: '140px'}}>
                                            <Icon width={25} icon={idCard} />
                                            <FormattedMessage id="app.cni.statut.nok" />
                                        </Badge>
                                    </div>
                                    
                                    
                                )
                        }
                        </>
                            
                    }

                    {(!isEmpty(infosStatus?.numeroPieceTelco) || !isEmpty(infosStatus?.numeroPieceOm))&&
                        <>
                            {
                                laodingInfosStatus ?
                                <>Loading...</>
                                :
                                    infosStatus?.statut ?
                                    <FormattedMessage id="app.status.conforme.text" />
                                    :
                                    <>
                                        <FormattedMessage id="app.status.nonConforme.text1" /> <a href="https://wa.me/+237697414141">https://wa.me/+237697414141</a> <FormattedMessage id="app.status.nonConforme.text2" />
                                    </>
                            }
                        </>
                        
                    }
                    </div>
                </>
                :
                <ErrorLoading
                    errorText="app.error.message"
                    actionText="Recharger"
                    actionClick={() => setIsReload(true)}
                />
            }
                    
                    
        </div>
            
    )
}

const mapStateToProps = createStructuredSelector({
    infosIdentification: selectInfosIdentification,
    infosTelco: selectInfosTelco,
    infosOm: selectInfosOm,
    infosStatus: selectInfosStatus,
    number: selectNumber
});

const mapDispatchToProps = (dispatch) => ({
    getIdentificationsAsync: (msisdn) => dispatch(getIdentificationsAsync(msisdn)),
    getInfosOMAsync: (msisdn) => dispatch(getInfosOMAsync(msisdn)),
    getInfosTelcoAsync: (msisdn) => dispatch(getInfosTelcoAsync(msisdn)),
    getStatusAsync: (msisdn) => dispatch(getStatusAsync(msisdn))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);