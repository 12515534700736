export default {
    fr: {
      "app.title": "Statut d'identification du : {number}",
      "app.identity.title": "Identité",
      "app.name.label": "Nom et prénom",
      "app.cni.label": "Numéro CNI",
      "app.cni.expirationDate.label": "Date d'expiration CNI",
      "app.dateNaissance.label": "Date naissance",
      "app.numberLine.label": "Lignes rattachées",
      "app.numberLine.om.label": "Comptes rattachés",
      "app.status.conforme.text": "Cher Client votre identification est conforme, Orange vous remercie pour la confiance",
      "app.status.nonConforme.text1":"Cher client votre identification est NON conforme, veuillez envoyer votre CNI valide par WhatsApp au 697414141 ou cliquer sur le lien ",
      "app.status.nonConforme.text2": " pour une mise à jour automatique",
      "app.form.number.label": "Numéro de téléphone",
      "app.form.message1":"Renseignez votre Numéro de téléphone et cliquer",
      "app.form.message2":"Cliquez ici",
      "app.form.message3":"pour recevoir le code de connexion",
      "app.error.message": "Erreur! Réessayer plutard.",
      "app.status.ok": "Conforme",
      "app.status.notOk": "Non conforme",
      "app.noAccount.message": "Vous n'avez pas de compte! Rendez vous en agence pour vous créer un compte.",
      "app.waiting.message": "La recherche pourra prendre quelques minutes",
      "app.cni.statut.ok": "CNI valide",
      "app.cni.statut.nok": "CNI expirée",
      "app.nbrLine.notfound": "inconnu",
      "app.input.code": "Saisir le code reçu par SMS"
    },
    en: {
        "app.title": "Identification status of : {number}",
        "app.identity.title": "Identity",
        "app.name.label": "Last name and first name",
        "app.cni.label": "ID Card number",
        "app.cni.expirationDate.label": "Expiration date of Id Card",
        "app.dateNaissance.label": "Date of birth",
        "app.numberLine.label": "Ratached lines",
        "app.numberLine.om.label": "Associated accounts",
        "app.status.conforme.text": "Dear Customer your identification is compliant, Orange thanks you for your trust",
        "app.status.nonConforme.text1":"Dear customer your ID is NOT compliant, please send your valid NIC by WhatsApp to 697414141 or click on the link ",
        "app.status.nonConforme.text2": " for an automatic update",
        "app.form.number.label": "Phone number",
        "app.form.message1":"Fill in your phone number and click",
        "app.form.message2":"Click here",
        "app.form.message3":"to receive the connection code",
        "app.error.message": "Error! Try again later.",
        "app.status.ok": "Compliant",
        "app.status.notOk": "Not Compliant",
        "app.noAccount.message": "You don't have an account! Go to the agency to create an account.",
        "app.waiting.message": "The search may take a few minutes",
        "app.cni.statut.ok": "valide IDC",
        "app.cni.statut.nok": "expired IDC",
        "app.nbrLine.notfound": "not found",
        "app.input.code": "Enter the code received by SMS"
      },
  };
  