import { statusConfig } from "../config/constants";

const convertStatusToColor = (status) => {
  switch (status) {
    case statusConfig.STATUS_CONFORM.toString():
      return "#32c832";

    case statusConfig.STATUS_NOT_CONFORM.toString():
      return "#cd3c14";

    case statusConfig.STATUS_CNI.toString():
      return "rgb(178, 129, 220)";
      
    default:
      return "#949494";
  }
};

export default convertStatusToColor;
